
.mat-mdc-form-field-infix {
    width: 100% !important;
}

.mat-mdc-tab {
    height: 40px !important;
    line-height: 32px !important;
    //font-size: 14px !important;
    //letter-spacing: 0.5px !important;
}

.mdc-tab {
    padding-left: 8px !important;
    padding-right: 8px !important;
    min-width: 0 !important;
}

@media screen and (max-width: 500px) {
    .image-modal .mat-mdc-dialog-container {
        max-width: none !important;
        width: 200% !important;
        margin: 0 -30px;
    }
}

.panel-heading {
    margin-left: 8px;
    font-size: 1rem;
    font-weight: 400;
}

.radio-group {
    label {
        margin-top: 2px !important;
        margin-left: -8px !important;
    }
}

.mdc-list-item__primary-text {
    overflow: hidden !important;
}

.mat-sort-header-container {
    justify-content: center !important;
}

.small-icon-button {
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role=img] {
        width: 24px;
        height: 24px;
        font-size: 24px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .mat-mdc-button-touch-target {
        width: 32px !important;
        height: 32px !important;
    }
}

.very-small-icon-button {
    width: 24px !important;
    height: 24px !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role=img] {
        width: 16px;
        height: 16px;
        font-size: 16px;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .mat-mdc-button-touch-target {
        width: 24px !important;
        height: 24px !important;
    }
}

.medium-icon-button {
    width: 48px !important;
    height: 48px !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    font-size: 48px !important;

    & > *[role=img] {
        width: 32px;
        height: 32px;
        font-size: 32px;

        svg {
            width: 32px;
            height: 32px;
        }
    }

    .mat-mdc-button-touch-target {
        width: 48px !important;
        height: 48px !important;
    }
}

.big-icon-button {
    width: 64px !important;
    height: 64px !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    font-size: 48px !important;

    & > *[role=img] {
        width: 48px;
        height: 48px;
        font-size: 48px;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    .mat-mdc-button-touch-target {
        width: 64px !important;
        height: 64px !important;
    }
}

.small-icon {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
}

.medium-icon {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
}

.big-icon {
    font-size: 48px !important;
    width: 48px !important;
    height: 48px !important;
}

.clickable {
    cursor: pointer;
}

.clickable-div {
    -webkit-tap-highlight-color: transparent; /* For Safari on iOS */
    -webkit-touch-callout: none; /* Prevents callout */
    outline: none; /* Prevents outline */
}

.mat-vertical-content {
    padding: 4px 16px 4px !important;
}

html {
    --mat-stepper-header-height: 56px;
}

.bold {
    font-weight: 700 !important;
}

.soon {
    color: #dfc857 !important;
}

.today {
    color: #dfc857 !important;
    font-weight: 700 !important;
}

.now {
    color: var(--accent-color) !important;
    font-weight: 700 !important;
}

.past {
    color: #bd5a5a !important;
    font-weight: 700 !important;
}

ol {
    padding-left: 1rem !important;
}

ul {
    padding-left: 1.5rem !important;
}
