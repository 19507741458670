/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use './_theme-colors' as theme-colors;

@include mat.elevation-classes();
@include mat.app-background();

$dark-theme: mat.define-theme((
    color: (
        theme-type: dark,
        primary: theme-colors.$primary-palette,
        tertiary: theme-colors.$tertiary-palette,
    ),
));

$light-theme: mat.define-theme((
    color: (
        theme-type: light,
        primary: theme-colors.$primary-palette,
        tertiary: theme-colors.$tertiary-palette,
    ),
));

html {
    // Apply the dark theme by default
    @include mat.all-component-themes($dark-theme);
    @include mat.color-variants-backwards-compatibility($dark-theme);

    // Apply the light theme only when the user prefers light themes.
    @media (prefers-color-scheme: light) {
        // Use the `-color` mixins to only apply color styles without reapplying the same
        // typography and density styles.
        @include mat.all-component-themes($light-theme);
        @include mat.color-variants-backwards-compatibility($light-theme);
    }
}

:root {
    --mat-app-background-color: #141F29;
    --mat-app-background-color-variant: #1c2a36;
    --secondary-text-color: #b1b8be;
    --mat-app-text-color: #edf4ff;
    --secondary-panel-color: #374d62;

    @media (prefers-color-scheme: light) {
        --mat-app-background-color: #f6f8fa;
        --mat-app-background-color-variant: #d4dbe3;
        --secondary-text-color: #6b6f73;
        --mat-app-text-color: #000000;
        --secondary-panel-color: #b1cde6;
    }

    --primary-color: #df9a57;
    --accent-color: #df9a57;
    --mat-sys-on-primary: #000000;
    --mat-sys-on-accent: #000000;

    --mat-table-row-item-outline-color: #374d62;
    --mdc-outlined-button-outline-color: #374d62;

    --mat-sidenav-container-shape: 0px;
    --mat-dialog-container-shape: 0px;

    --mat-sidenav-content-text-color: var(--mat-app-text-color);
    --mat-divider-color: color-mix(in srgb, var(--mat-app-text-color) 20%, transparent);
}

.theme-light {
    @include mat.all-component-themes($light-theme);
    @include mat.color-variants-backwards-compatibility($light-theme);
}

.theme-light-root {
    --mat-app-background-color: #f6f8fa;
    --mat-app-background-color-variant: #d4dbe3;
    --secondary-text-color: #6b6f73;
    --mat-app-text-color: #000000;
    --secondary-panel-color: #b1cde6;

    --primary-color: #df9a57;
    --accent-color: #df9a57;
    --mat-sys-on-primary: #000000;
    --mat-sys-on-accent: #000000;

    --mat-table-row-item-outline-color: #374d62;
    --mdc-outlined-button-outline-color: #374d62;

    --mat-sidenav-container-shape: 0px;
    --mat-dialog-container-shape: 0px;

    --mat-sidenav-content-text-color: var(--mat-app-text-color);
    --mat-divider-color: color-mix(in srgb, var(--mat-app-text-color) 20%, transparent);
}

.theme-dark {
    @include mat.all-component-themes($dark-theme);
    @include mat.color-variants-backwards-compatibility($dark-theme);
}

.theme-dark-root {
    --mat-app-background-color: #141F29;
    --mat-app-background-color-variant: #1c2a36;
    --secondary-text-color: #b1b8be;
    --mat-app-text-color: #edf4ff;
    --secondary-panel-color: #374d62;

    --primary-color: #df9a57;
    --accent-color: #df9a57;
    --mat-sys-on-primary: #000000;
    --mat-sys-on-accent: #000000;

    --mat-table-row-item-outline-color: #374d62;
    --mdc-outlined-button-outline-color: #374d62;

    --mat-sidenav-container-shape: 0px;
    --mat-dialog-container-shape: 0px;

    --mat-sidenav-content-text-color: var(--mat-app-text-color);
    --mat-divider-color: color-mix(in srgb, var(--mat-app-text-color) 20%, transparent);
}

a {
    color: var(--accent-color);
}

.mat-app-background {
    background-color: var(--mat-app-background-color) !important;
}

.mat-drawer-container {
    background-color: var(--mat-app-background-color) !important;
    overflow: visible !important;
}

.mat-drawer-content {
    // For the gradient effect on the game's background
    overflow: visible !important;
}

.mat-toolbar {
    background-color: var(--mat-app-background-color) !important;
    //background-color: #172330 !important;
}

.mat-stepper-vertical {
    background-color: var(--mat-app-background-color) !important;
}

.mat-stepper-horizontal {
    background-color: var(--mat-app-background-color) !important;
}

.mat-step-label {
    min-width: 0 !important;
}

.mat-mdc-dialog-container {
    background-color: var(--mat-app-background-color) !important;
    --mdc-dialog-container-color: var(--mat-app-background-color) !important;
}

.mdc-dialog__title {
    background-color: var(--mat-app-background-color) !important;
}

.mdc-dialog__actions {
    background-color: var(--mat-app-background-color) !important;
}

.mat-mdc-select-panel {
    background-color: var(--mat-app-background-color) !important;
}

.mat-mdc-autocomplete-panel {
    background-color: var(--mat-app-background-color) !important;
}

.mat-mdc-menu-panel {
    background-color: var(--mat-app-background-color-variant) !important;
}

.mat-drawer {
    background-color: var(--mat-app-background-color) !important;
}

.mat-drawer-side {
    border-right: none !important; //solid 1px rgba(255, 255, 255, 0.06);
    border-left: none !important; //solid 1px rgba(255, 255, 255, 0.06);
}

.mat-expansion-panel {
    background-color: var(--mat-app-background-color-variant) !important;
}

.mat-expansion-panel-header {
    padding: 0 16px !important;
    background-color: var(--mat-app-background-color-variant) !important;
}

.mat-form-field {
    background-color: var(--mat-app-background-color-variant) !important;
}

.mat-mdc-text-field-wrapper {
    background-color: var(--mat-app-background-color-variant) !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: color-mix(in srgb, var(--mat-app-background-color) 75%, transparent) !important;
    // Whenever the drawer overlay covers the full height, use this instead
    //background-color: rgba(0, 0, 0, 0.4) !important;
}

.mat-datepicker-content-container {
    background-color: var(--mat-app-background-color-variant) !important;
}

.mat-mdc-table {
    background-color: var(--mat-app-background-color-variant) !important;
}

.mat-mdc-card {
    background-color: var(--mat-app-background-color) !important;
}

.mat-button-toggle {
    background-color: var(--mat-app-background-color) !important;
    color: var(--secondary-text-color);
}

.mat-button-toggle-checked {
    border: 1px solid #466079 !important;
    background-color: var(--mat-app-background-color-variant) !important;
    color: var(--mat-app-text-color);
}

.mat-mdc-menu-panel {
    .mat-button-toggle {
        background-color: var(--mat-app-background-color-variant) !important;
    }

    .mat-button-toggle-checked {
        background-color: #334e69 !important;
    }
}

.mat-mdc-standard-chip {
    background-color: var(--secondary-panel-color) !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary, .mat-mdc-standard-chip .mdc-evolution-chip__action--primary, .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden !important;
}

.opened {
    background-color: var(--secondary-panel-color) !important;
}
