
html, body {
    height: 100%;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    //background: var(--mat-app-background-color);
}

// Autofill changes colors, this is a hack around it
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 50000s 0s, color 50000s 0s;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.wrap {
    flex-wrap: wrap;
}

.start {
    justify-content: start;
}

.end {
    justify-content: end;
}

.center {
    justify-content: center;
}

.space-between {
    align-content: stretch;
    justify-content: space-between;
}

.space-around {
    align-content: stretch;
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: start;
}

.align-end {
    align-items: end;
}

.fillWidth {
    width: 100%;
}

.fill {
    width: 100%;
    flex-grow: 1;
}

.flex {
    flex: 1;
}
